/*
 * These changes are called between utilities and utilities/api
 * 
 * Remove the responsive variations like this:
 * $utilities: make-utility-non-responsive('foo');
 * 
 * Remove a utility like this:
 * $utilities: map-remove($utilities, 'foo');
 * 
 * Rename a utility like this (note that there may be impacts upon local variable names, so some renames may not be possible)
 * $utilities: rename-utility('opacity', 'foo');
 * 
 * Rename a utility local variable like this:
 * $utilities: rename-utility-variable('background-color', 'oldfoo', 'newfoo');
 *
 * To add more utility modifiers like the above, add functions to functions.scss
 */

// Remove responsive utilities
$utilities: make-utility-non-responsive('negative-margin');
$utilities: make-utility-non-responsive('negative-margin-x');
$utilities: make-utility-non-responsive('negative-margin-y');
$utilities: make-utility-non-responsive('negative-margin-top');
$utilities: make-utility-non-responsive('negative-margin-end');
$utilities: make-utility-non-responsive('negative-margin-bottom');
$utilities: make-utility-non-responsive('negative-margin-start');

// Remove utilities that aren't in use
$utilities: map-remove($utilities, 'border-opacity');
$utilities: map-remove($utilities, 'font-family');
$utilities: map-remove($utilities, 'font-size');
$utilities: map-remove($utilities, 'font-style');
$utilities: map-remove($utilities, 'font-weight');
$utilities: map-remove($utilities, 'gap');
$utilities: map-remove($utilities, 'min-viewport-height');
$utilities: map-remove($utilities, 'min-viewport-width');
$utilities: map-remove($utilities, 'pointer-events');
$utilities: map-remove($utilities, 'rounded-bottom');
$utilities: map-remove($utilities, 'rounded-end');
$utilities: map-remove($utilities, 'rounded-start');
$utilities: map-remove($utilities, 'rounded-top');
$utilities: map-remove($utilities, 'shadow');
$utilities: map-remove($utilities, 'text-opacity');
$utilities: map-remove($utilities, 'user-select');
$utilities: map-remove($utilities, 'viewport-height');
$utilities: map-remove($utilities, 'word-wrap');

// Remove utility CSS variable
$utilities: remove-utility-variable('background-color', 'bg-opacity'); // Have set globally in root.scss
$utilities: remove-utility-variable('color', 'text-opacity'); // Have set globally in root.scss

// Remove @media=print variations
$utilities: make-utility-non-print('display');

// Add utilities
$utilities: map-merge($utilities, ('min-width': (property: min-width, class: min-w, values: (50: 50%))));
$utilities: map-merge($utilities, ('max-viewport-height': (property: max-height, class: max-vh, values: (50: 50vh))));

// Add width utilities values
$utilities: add-utility-values('width', (
	1: 1rem,
	2: 2rem,
	3: 3rem,
	10: 10%,
	15: 15%,
	20: 20%,
	30: 30%,
));

// Add max-width utilities values
$utilities: add-utility-values('max-width', (
	20ch: 20ch,
	30ch: 30ch,
	40ch: 40ch,
	50ch: 50ch,
	60ch: 60ch,
	70ch: 70ch,
	80ch: 80ch,
), true); // true wipes existing values

// Add max-height utilities values
$utilities: add-utility-values('max-height', (
	1: 1rem,
	2: 2rem,
	3: 3rem,
	4: 4rem,
	5: 5rem,
	6: 6rem,
	8: 8rem,
	10: 10rem,
	15: 15rem,
), true); // true wipes existing values

// Set-up gradients
$gradient-map: ();
@each $colour-pair in $colour-list-gradients {
	@each $colour-name-top, $colour-name-bottom in $colour-pair {
		$colour-value-top: map.get($colour-map-master, $colour-name-top);
		$colour-value-bottom: map.get($colour-map-master, $colour-name-bottom);
		$colour-value-middle: mix($colour-value-top, $colour-value-bottom, 50%);
		$gradient-map: map-merge($gradient-map, ("#{$colour-name-top}-#{$colour-name-bottom}": linear-gradient(var(--gradient-direction, to bottom), $colour-value-top var(--gradient-start, 10%), $colour-value-middle var(--gradient-middle, 50%), $colour-value-bottom var(--gradient-end, 90%))));
	}
}

// Add gradients to utilities
$utilities: map-merge($utilities, (
	"background-gradient": (
		property: background-image,
		class: bg-gradient,
		values: $gradient-map
	)
));

// Override how we do text-colours - annoyingly we can't set both the --var and the attribute to be $value
// Setting the --var as well means we can access the value in child elements
$utilities: map-remove($utilities, 'color');
@each $name, $value in $utilities-text {
	.text-#{$name} {
		--#{$prefix}text-color-rgb: var(--#{$prefix}#{$name}-rgb) !important;
		color: rgba-css-var("#{$prefix}#{$name}", "text") !important; // I'd liked to have used "text-color" rather than re-use the colour name, but browsers don't like it
	}
}

// Override how we do background-colours - annoyingly we can't set both the --var and the attribute to be $value
// Setting the --var as well means we can access the value in child elements
$utilities: map-remove($utilities, 'background-color');
@each $name, $value in $utilities-bg-colors {
	.bg-#{$name} {
		--#{$prefix}bg-rgb: var(--#{$prefix}#{$name}-rgb) !important;
		background-color: rgba-css-var("#{$prefix}#{$name}", "bg") !important; // I'd liked to have used "bg" rather than re-use the colour name, but browsers don't like it
	}
}

// Override text opacities as we have two different CSS variables. TODO: a nice way of managing this? E.g. detect dark backgrounds and set in bg-*?
@each $name, $value in (80: 0.8, 100: 1) {
	.text-opacity-#{$name} {
		--#{$prefix}text-opacity: #{$value};
		--#{$prefix}paragraph-opacity: #{$value};
	}
}

// Remove blank utilities due to Bootstrap bug
@each $utility, $data in $utilities {
	@if length(map-get($data, values)) == 0 {
		$utilities: map-remove($utilities, $utility);
	} 
}

// Having the utilities for every breakpoint is kind of pointless
// No *-xl-* utility classes are currently in use 
// Removing breakpoints doesn't affect grid classes (col-*, g-* etc)

$grid-breakpoints-backup: $grid-breakpoints;
$grid-breakpoints: map-remove($grid-breakpoints, 'xl');

// Now run Bootstrap's APIs
@import "~BootstrapSass/utilities/api";

// Revent changes to breakpoints, otherwise it messes with media queries in our SCSS
$grid-breakpoints: $grid-breakpoints-backup;