// IMPORTANT: if adding any new variables, make sure they are also included in $colour-map-master

// Give as you Live Brand - Core
$blue:   #44b4e4; // Online Shopping
$green:  #00973a; // Instore Shopping
$grey:   #454545; // Text
$navy:   #152f58; // Brand
$pink:   #e72869; // Donate

// Give as you Live Brand - Supplementary
$marine: #397be6;
$purple: #5336e3;
$steel:  #296699;
$teal:   #38b8b1;

// Contextual (overrides Bootstrap colours in alerts)
$black:  #000;
$orange: #e37e36;
$red:    #e33936;
$yellow: #f2b53a;
$white:  #fff;

// Leaderboard and reviews colours
$gold:   #f2b53a;
$bronze: #cd7f32;

// Product-specific colours
$energy-purple: #586285;
$share-gold:    #ffaf32;
$share-purple:  #edecfe;
$switch-green:  #307049;

// 
// Set up shades/tints
// 

$blue-10: #dff5ff;
$blue-25: #c0e6f6;
$blue-50: #9bd6f0;
$blue-75: #6fc5e9;

$bronze-10: #faede0; // Unused
$bronze-25: #f3dbc2;
$bronze-50: #e6be96; // Unused
$bronze-75: #da9f65; // Unused

$energy-purple-10: #f0f3fe; // Unused, not in maps
$energy-purple-25: #c3cae4; // Unused, not in maps
$energy-purple-50: #98a2c7; // Unused, not in maps
$energy-purple-75: #7781a6; // Unused, not in maps

$gold-10: #fcf4e3;
$gold-25: #fae2b1; // Unused
$gold-50: #f7d287; // Unused
$gold-75: #f5c25f; // Unused

$green-10: #e1faeb;
$green-25: #a7e4be;
$green-50: #64ca8b;
$green-75: #2caf5e;

$marine-10: #e8f1ff;
$marine-25: #cbdefe;
$marine-50: #8cb7fa;
$marine-75: #5c96f2;

$navy-5:  #f3f6fa;
$navy-10: #dfe7f4;
$navy-15: #d1dcf0; // Only used in navs, not in maps
$navy-20: #c3d2eb; // Only used in navs, not in maps
$navy-25: #b0c4e5;
$navy-50: #6584b7;
$navy-75: #2f4e80;

$orange-10: #ffebdd;
$orange-25: #fccdaa;
$orange-50: #f4b182;
$orange-75: #ec975b;

$pink-10: #ffe8ef;
$pink-25: #fecddd;
$pink-50: #faa1be;
$pink-75: #f26494;

$purple-10: #e6e4f5;
$purple-25: #c4bbf0;
$purple-50: #9d8dec;
$purple-75: #745fe7;

$red-10: #fce0e0;
$red-25: #f8bbba;
$red-50: #f28988;
$red-75: #ed605e;

$steel-10: #e5eff8;
$steel-25: #c2d8eb;
$steel-50: #92b9d9;
$steel-75: #588eb9;

$switch-green-10: #e7f6ec; // Only in $colour-map-products
$switch-green-25: #b3e3c6; // Unused, not in maps
$switch-green-50: #79b791; // Only in $colour-map-products
$switch-green-75: #478d62; // Unused, not in maps

$teal-10: #e4f5f4;
$teal-25: #cdedec;
$teal-50: #a6dbd8;
$teal-75: #76c9c5;

$yellow-10: #fcf4e3;
$yellow-25: #fae2b1;
$yellow-50: #f7d287;
$yellow-75: #f5c25f;

// 
// Bootstrap overrides
// 

// Bootstrap colours
$primary: $navy !default; // Was #0d6efd (Bootstrap 5 blue)

// Text colours
$text-colour: $navy !default;
$text-color: $text-colour !default;
$body-color: $text-colour !default;
$color-contrast-dark: $text-colour !default;
$dropdown-color: $text-colour !default;
$dropdown-link-color: $text-colour !default;
$dropdown-link-hover-bg: $navy-10;

// 
// Create the maps - groups
// 

$colour-map-brand: (
	'blue':   $blue,
	'green':  $green,
	'grey':   $grey,
	'navy':   $navy,
	'pink':   $pink,
);

$colour-map-supplementary: (
	'marine': $marine,
	'purple': $purple,
	'steel':  $steel,
	'teal':   $teal,
);

$colour-map-contextual: (
	'black':  $black,
	'orange': $orange,
	'red':    $red,
	'yellow': $yellow,
	'white':  $white,
);

$colour-map-leaderboards: (
	'gold':      $gold,
	'gold-10':   $gold-10,
	'gold-25':   $gold-25,
	'gold-50':   $gold-50,
	'gold-75':   $gold-75,
	'bronze':    $bronze,
	'bronze-10': $bronze-10,
	'bronze-25': $bronze-25,
	'bronze-50': $bronze-50,
	'bronze-75': $bronze-75,
);

$colour-map-products: (
	'energy-purple-10':$energy-purple-10,
	'energy-purple':   $energy-purple,
	'share-gold':      $share-gold,
	'share-purple':    $share-purple,
	'switch-green':    $switch-green,
	'switch-green-10': $switch-green-10,
	'switch-green-50': $switch-green-50,
);

// 
// Create the maps - shades/tints
// 

$colour-map-blues: (
	'blue-10': $blue-10,
	'blue-25': $blue-25,
	'blue-50': $blue-50,
	'blue-75': $blue-75,
);

$colour-map-greens: (
	'green-10': $green-10,
	'green-25': $green-25,
	'green-50': $green-50,
	'green-75': $green-75,
);

$colour-map-marines: (
	'marine-10': $marine-10,
	'marine-25': $marine-25,
	'marine-50': $marine-50,
	'marine-75': $marine-75,
);

$colour-map-navys: (
	'navy-5': $navy-5,
	'navy-10': $navy-10,
	'navy-25': $navy-25,
	'navy-50': $navy-50,
	'navy-75': $navy-75,
);

$colour-map-oranges: (
	'orange-10': $orange-10,
	'orange-25': $orange-25,
	'orange-50': $orange-50,
	'orange-75': $orange-75,
);

$colour-map-pinks: (
	'pink-10': $pink-10,
	'pink-25': $pink-25,
	'pink-50': $pink-50,
	'pink-75': $pink-75,
);

$colour-map-purples: (
	'purple-10': $purple-10,
	'purple-25': $purple-25,
	'purple-50': $purple-50,
	'purple-75': $purple-75,
);

$colour-map-steels: (
	'steel-10': $steel-10,
	'steel-25': $steel-25,
	'steel-50': $steel-50,
	'steel-75': $steel-75,
);

$colour-map-teals: (
	'teal-10': $teal-10,
	'teal-25': $teal-25,
	'teal-50': $teal-50,
	'teal-75': $teal-75,
);

$colour-map-reds: (
	'red-10': $red-10,
	'red-25': $red-25,
	'red-50': $red-50,
	'red-75': $red-75,
);

$colour-map-yellows: (
	'yellow-10': $yellow-10,
	'yellow-25': $yellow-25,
	'yellow-50': $yellow-50,
	'yellow-75': $yellow-75,
);

// 
// Add to master colour map
// 

$colour-map-master: map-merge($colour-map-master, $colour-map-brand);
$colour-map-master: map-merge($colour-map-master, $colour-map-supplementary);
$colour-map-master: map-merge($colour-map-master, $colour-map-contextual);
$colour-map-master: map-merge($colour-map-master, $colour-map-leaderboards);
$colour-map-master: map-merge($colour-map-master, $colour-map-products);
$colour-map-master: map-merge($colour-map-master, $colour-map-blues);
$colour-map-master: map-merge($colour-map-master, $colour-map-greens);
$colour-map-master: map-merge($colour-map-master, $colour-map-navys);
$colour-map-master: map-merge($colour-map-master, $colour-map-oranges);
$colour-map-master: map-merge($colour-map-master, $colour-map-pinks);
$colour-map-master: map-merge($colour-map-master, $colour-map-purples);
$colour-map-master: map-merge($colour-map-master, $colour-map-reds);
$colour-map-master: map-merge($colour-map-master, $colour-map-yellows);
$colour-map-master: map-merge($colour-map-master, $colour-map-marines);
$colour-map-master: map-merge($colour-map-master, $colour-map-purples);
$colour-map-master: map-merge($colour-map-master, $colour-map-steels);
$colour-map-master: map-merge($colour-map-master, $colour-map-teals);

// 
// And extract keys to make a list
// 

$colour-list-brand:         map-keys($colour-map-brand);
$colour-list-supplementary: map-keys($colour-map-supplementary);
$colour-list-contextual:    map-keys($colour-map-contextual);
$colour-list-leaderboards:  map-keys($colour-map-leaderboards);
$colour-list-products:      map-keys($colour-map-products);
$colour-list-blues:         map-keys($colour-map-blues);
$colour-list-greens:        map-keys($colour-map-greens);
$colour-list-navys:         map-keys($colour-map-navys);
$colour-list-oranges:       map-keys($colour-map-oranges);
$colour-list-pinks:         map-keys($colour-map-pinks);
$colour-list-purples:       map-keys($colour-map-purples);
$colour-list-reds:          map-keys($colour-map-reds);
$colour-list-yellows:       map-keys($colour-map-yellows);
$colour-list-marines:       map-keys($colour-map-marines);
$colour-list-purples:       map-keys($colour-map-purples);
$colour-list-steels:        map-keys($colour-map-steels);
$colour-list-teals:         map-keys($colour-map-teals);
