// Change the breakpoints and max container size
// By only having one $container-max-widths value, the container will be fluid up to that breakpoint

@if $stylesheet == 'clickthrough' {
    // Clickthrough - no breakpoints
    $grid-breakpoints: ();
    $container-max-widths: ();

} @else if $stylesheet == 'donate-form' or $stylesheet == 'check-in' {
    // Donate form - stop at MD
    $grid-breakpoints: map-remove($grid-breakpoints, "lg", "xl");
    $container-max-widths: ("md": map-get($container-max-widths, "md"));

} @else {
    // Default - stop at XL
    $grid-breakpoints: map-remove($grid-breakpoints, "xxl");
    $container-max-widths: ("xl": map-get($container-max-widths, "xl"));
}
