/**
 * GAYL THEME for Owl Carousel v2.3.4
 * Don't overwrite with the default theme that comes with Owl Carousel!
 *
 * The main thing this does is make the prev/next arrows appear to the side
 *
 * To-do: what happens if placed on a dark background?
 */

$owl-grid-margin: $grid-gutter-width * 0.5;
$owl-dot-size: .75rem;
$owl-dot-margin-y: 0;
$owl-dot-margin-x: .5rem;

// Copied from ~Vendor/owl.carousel/src/scss/owl.carousel.scss - only import the features we need
@import "~Vendor/owl.carousel/src/scss/core";
// @import "~Vendor/owl.carousel/src/scss/animate";
// @import "~Vendor/owl.carousel/src/scss/autoheight";
// @import "~Vendor/owl.carousel/src/scss/lazyload";
// @import "~Vendor/owl.carousel/src/scss/video";

.owl-container .owl-carousel,
.owl-container.owl-carousel {
	@include media-breakpoint-up(md) {
		margin-left: calc(var(--gutter-x) / -2);
		margin-right: calc(var(--gutter-x) / -2);
		width: calc(100% + var(--gutter-x));
	}

	@include media-breakpoint-down(sm) {
		--box-margin: 1rem;
	}
}

.owl-loading {
	overflow: hidden;
	max-height: 150px;
}

.owl-peek {
	overflow: visible;
	.owl-stage-outer {
		overflow: visible;
	}
}

.owl-stage-outer {
	z-index: 2; // Fix for dragging (pos:abs messes with default z-indexes)
}

.owl-stage {
	display: flex;
	flex-wrap: wrap;
}

.owl-show-hidden {
	.owl-stage-outer {
		overflow: visible
	}

	.owl-item:not(.active) {
		opacity: 0.5;
	}
}

.owl-carousel .item, 
.owl-item, 
.owl-carousel .box {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

// Detect if carousel has .box elements
.owl-carousel:has(.box) {
	.owl-item > .row > div,
	.item > .row > div {
		display: flex;
	}

	// Remove box margin - helps everything align better
	.owl-stage-outer {
		margin-bottom: -$grid-gutter-width;
	}

	.owl-dots {
		padding-top: $grid-gutter-width;
	}
}

.owl-carousel .box__content {
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	.btn {
		margin-top: auto;
	}
}

.owl-carousel .item {
	padding: $grid-gutter-width * .5; // Don't use var() as .owl-container will override

	& > p {
		margin: 0;
		&:first-of-type {
			margin-top: 1rem;
		}
	}

	&:has(.box) {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.owl-nav {
	top: 0;
	left: 50%; // Combined with translateX() centers it and with 98vw ensures no overflow (not 100vw just so there's a little bit of padding)
	z-index: 2; // Fix for dragging (pos:abs messes with default z-indexes)
	width: 100%; // Fallback
	position: absolute;
	pointer-events: none; // Stops the div itself being clickable
	height: calc(100% - 32px);
    transform: translateX(-50%);
	width: clamp(0px, calc(100% + var(--owl-nav-gap, 4rem)), 98vw);

	// This undoes a global override that causes unnecessary CLS on mobile
	i:empty {
		width: unset;
	}
}

.modal .owl-nav {
	--owl-nav-gap: #{$modal-header-padding-x};
}

.owl-prev,
.owl-next {
	top: 50%; // Pushes half way down images (transform:translate then used to push up so centre is 50% down instead of top edge)
	outline: none; // Stops blue glow
	position: absolute;
	pointer-events: all; // Makes arrows themselves clickable although parent div is not
	transition: $transition-base;
	&:hover {
		color: $marine !important; // !important because it's fewer characters than nesting the full declaration (.owl-carousel .owl-nav button.owl-prev) which is necessary to overwrite defaults
	}
	&.disabled {
		opacity: 0.5;
	}
}

.owl-prev {
	left: 0;
	transform: translateY(-50%);
}

.owl-next {
	right: 0;
	transform: translateY(-50%);
}

.owl-carousel[data-dots="false"] .owl-nav {
	height: 100%;
}

.owl-dots {
	width: 100%;
	text-align: center;
}

.owl-dot span {
	margin: $owl-dot-margin-y $owl-dot-margin-x;
	width: $owl-dot-size;
	height: $owl-dot-size;
	display: block;
	border-radius: 50%;
	background: $grey-50;
	transition: $transition-base;
}

.owl-carousel:has(.list-box):not(:has(.box)):not(:has(.box__content:last-child:nth-child(even))) {
	.owl-dot {
		margin-top: $grid-gutter-width;
	}
}

.owl-dot.active span, 
.owl-dot:hover span {
	background: $navy; 
	background: var(--owlDot, $navy); 
}
.band--dark .owl-dot:hover span {
	background: var(--owlDot, currentColor); 	
}

.owl-item img:not(.w-100):not(.owl-ignore) {
	max-width: 100%;
	width: auto !important;
	transition: opacity .2s ease-in-out;
	// -webkit-backface-visibility: hidden; // fixes weird size change (chrome bug) // if you find it again, put the link here!
}

.owl-carousel a:hover img {
	opacity: 0.8 !important;
}

.owl-carousel .item .offer-badge { // Increased donation badge
	width: 100%;
	padding: 3px 5px; 
}

// Extra styles for full-width sliders, which should have a background image
.container-fluid:not(.container-xl) .owl-carousel {
	.item {
		padding: 0;
	}
	.owl-nav {
		color: #fff;
	}
	.owl-prev {
		transform: translate(100%, -50%);
	}
	.owl-next {
		transform: translate(-100%, -50%);
	}
}

.band--dark .owl-nav button:hover {
	opacity: .5;
	color: white !important;
}

// IE Hack - flexbox not properly supported
.browser--internet-explorer .owl-carousel {
	.item, .item a, .item img {
		min-height: 1px;
		height: auto;
	}
}