/**
 *   Global styles for text
 *   Aim is to use standardised set rather than duplicating the same styles everywhere
 *   Avoid using colours wherever possible, these should be set by the container
 */

// Automatically sort out hanging words aka widows - as of October 2023 only supported by Chrome and Edge
body {
	text-wrap: pretty;
}

h1, h2, h3, h4, h5, h6 {
	text-wrap: balance;
}

// Set up default colours - we want standard text to be slightly lighter than headings
// By changing the opacity, we help it work better on different backgrounds
p:not(.form-label), 
li,
address,
th > .text-sm,
label + span,
label > span,
.form-check {
	--text-opacity: var(--paragraph-opacity, #{$paragraph-text-opacity});
	color: rgba(var(--text-color-rgb), var(--text-opacity));
}

.alert {
	p, li {
		color: var(--alert-color);
	}
}

mark {
	$mark-gradient-default: if($brand == $navy, $marine, $brand);
	$mark-gradient: rgba(var(--bg-rgb, to-rgb($mark-gradient-default)), var(--opacity, 0.5));

	color: inherit;
	background-size: 100% 20%;
	background-position: 0% 90%;
	background-repeat: no-repeat;
	background-image: linear-gradient($mark-gradient, $mark-gradient); // Has to be a gradient to work

	// Needs more specificity to override standard .bg-* class
	&[class*=bg-] {
		background-color: unset !important;
	}

	&.opacity-100 {
		--opacity: 1;
	}
}

// Adds shadow, but more of a background blur than a standard drop-shadow
.text-shadow {
	text-shadow: 0 0 3rem $grey-80, 0 0 1rem $grey-80;
}

// Page Titles

// body parent needed to stop clashes with class names on the body tag
body [class*="pagetitle-"] {
	color: inherit;
	
	&:first-child {
		margin-top: 0;
	}

	&:first-child:last-child {
		margin-bottom: 0;
	}
}

.pagetitle-xs  { @include font-size-range(2rem,   2vw,   1.5rem);   } // 32px to 24px
.pagetitle-sm  { @include font-size-range(2.5rem, 2.5vw, 1.875rem); } // 40px to 30px
.pagetitle-md  { @include font-size-range(3rem,   3vw,   2.25rem);  } // 48px to 36px
.pagetitle-lg  { @include font-size-range(3.5rem, 3.5vw, 2.625rem); } // 56px to 42px
.pagetitle-xl  { @include font-size-range(4rem,   4vw,   3rem);     } // 64px to 48px
.pagetitle-2xl { @include font-size-range(4.5rem, 4.5vw, 3rem);     } // 72px to 48px
.pagetitle-3xl { @include font-size-range(5rem,   5vw,   3rem);     } // 80px to 48px

// Headings - the 5 sizes allow us to control the sizes of headings
// You should use the range of h1-6 tags still to control the DOM structure
// For comparison, regular sizes are:
// h1: 36px // h2: 30px // h3: 24px // h4: 18px // h5: 14px // h6: 12px
// heading-md is the standard one @ 26px

// body parent needed to stop clashes with class names on the body tag
body [class*="heading-"] {
	color: inherit;
}

.heading-xs { font-size: 1.125rem; } // 18px
.heading-sm { font-size: 1.375rem; } // 22px
.heading-md { font-size: 1.625rem; } // 26px
.heading-lg { font-size: 1.875rem; } // 30px
.heading-xl { font-size: 2.125rem; } // 34px

body [class*=pagetitle-], 
body [class*=heading-] {
	--text-opacity: 1;
	font-family: var(--font-headings);
}

label[class*="heading-"],
h2.form-label,
h3.form-label,
h4.form-label {
	font-family: var(--font-sans-serif);
	font-weight: $font-weight-bold;
}

.text-gantari {
	font-family: var(--font-headings) !important;
}

.text-oxygen {
	font-family: var(--font-sans-serif) !important;
}

// Standard text / paragraphs - the 5 sizes allow us to control the sizes of text
// text-md is the standard one @ 16px

.text-xs { font-size:  .75rem;  } // 12px
.text-sm { font-size:  .875rem; } // 14px
.text-md { font-size: 1rem;     } // 16px
.text-lg { font-size: 1.125rem; } // 18px
.text-xl { font-size: 1.25rem;  } // 20px

//
// Links
//

.link-underline {
	text-decoration: underline;
}

//
// Paragraphs
//

// Get rid of last margin in paragraph block
.p-set > :last-child { margin-bottom: 0; }
.p-collapse > :not(:last-child) { margin-bottom: 0; }
.p-collapse-all > * { margin-bottom: 0; }

// Lists

li > p {
	margin-bottom: 0;
}

.list-items {
	padding-left: 0px;
	margin-top: .5rem;

	&.fa-ul {
		margin-left: 2em;
		li:not(:last-child) {
			margin-bottom: .5rem;
		}
	}
}

ol.list-items {
	counter-reset: item;
	list-style: none;

	li {
		margin-bottom: 5px;
		counter-increment: item;
	}
	
	li:before {
		width: 1.4em;
		height: 1.4em;
		color: $grey;
		background: white;
		text-shadow: none;
		text-align: center;
		border-radius: 50%;
		margin-right: .5rem;
		display: inline-block;
		content: counter(item);
	}
}

.list-navy .fa-li .fa-circle { // changes icons only
	color: $navy;
}
.list-blue .fa-li .fa-circle { // changes icons only
	color: $blue;
}
.list-green .fa-li .fa-circle { // changes icons only
	color: $green;
}
.list-red .fa-li .fa-circle, // changes icons only
.list-red-cross .fa-circle { // this one is applied to .fa-layers rather than the list
	color: $danger !important;
}
.list-pink .fa-li .fa-circle { // changes icons only
	color: $pink;
}

ol.list-items.list-blue {
	li:before {
		background: $blue;
		color: white;
	}
}
ol.list-items.list-green {
	li:before {
		background: $green;
		color: white;
	}
}

.text-balance {
	text-wrap: balance;
}