/**
 *  Styles for the install page.
 *
 *  Some styles are specific to the current browser you're using, so you may need to change your user agent.
 */

// accordion used by how-the-dr-works
@import "~Sass/common/accordion";

// Browser icon shown inside button
.btn-browser {
	img {
		width: auto;
		height: calc(1em * var(--btn-line-height));
	}
}

.btn-app-store {
	width: auto;
	height: 3rem;
}

@import "~Sass/gayl/add-to-homescreen";

// Video

.install-video {
    position: relative;
}
.install-video--screen {
	top: 5.5%;
	left: 13%;
	width: 74%;
	z-index: 1;
	padding-top: 48%;
	overflow: hidden;
	position: absolute;
	border-radius: .25rem;
	background: #fff;

	> * {
		top: 0;
		left: 0;
		position: absolute;
	}
}

// Toast on welcome screen

.install-toast {
	top: 1rem;
	right: 1rem;
	width: 30rem;
	position: fixed;
	z-index: $zindex-sticky;
	border: 1px solid $silver;
	max-width: calc(100% - 2rem);

	&.browser-safari {
		left: 15%;
		right: unset;
	}
}

// Smart banner
.smart-banner-close {
	max-width: 60px;
}